import { useState } from "react";
import { RationButton } from "../RationButton";
import { RationContainer } from "../RationContainer";
import { RationFlex } from "../RationFlex";
import "./index.scss";
import { formatCell } from "./utils/formatCell";

export const RationTable = (props: {
  loading?: boolean;
  error?: { message: string };
  retryOnError?: () => void;
  csv?: string[][];
}) => {
  const { loading, error, csv, retryOnError } = props;

  const [scrollableRef, setScrollableRef] = useState<HTMLDivElement | null>(
    null
  );
  const [tableRef, setTableRef] = useState<HTMLTableElement | null>(null);

  const showScrollButtons =
    tableRef && scrollableRef
      ? tableRef.getBoundingClientRect().width >
        scrollableRef.getBoundingClientRect().width
      : undefined;

  const onScrollLeft = scrollableRef
    ? () => {
        if (scrollableRef) {
          scrollableRef.scrollBy({
            top: 0,
            left: -300,
            behavior: "smooth",
          });
        }
      }
    : undefined;

  const onScrollRight = scrollableRef
    ? () => {
        if (scrollableRef) {
          scrollableRef.scrollBy({
            top: 0,
            left: 300,
            behavior: "smooth",
          });
        }
      }
    : undefined;

  return (
    <RationContainer
      loading={loading}
      error={error}
      retryOnError={retryOnError}
      notFound={!loading && !error && !csv}
    >
      {csv && csv.length > 1 ? (
        <>
          <div className="RationTable_table" ref={setScrollableRef}>
            <table ref={setTableRef}>
              <thead>
                <tr>
                  {csv[0].map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {csv.slice(1, 5).map((row, rowIndex) => (
                  <tr key={row.join()}>
                    {row.map((cell, cellIndex) => (
                      <td key={`${cell}-${rowIndex}-${cellIndex}`}>
                        {formatCell(cell)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {showScrollButtons && (
            <RationFlex columnGap={8} mt={8}>
              <RationButton
                variant="secondary"
                onClick={onScrollLeft}
                title="scroll left"
                icon="chevron-left"
              />
              <RationButton
                variant="secondary"
                onClick={onScrollRight}
                title="scroll-right"
                icon="chevron-right"
              />
            </RationFlex>
          )}
        </>
      ) : (
        "No preview available."
      )}
    </RationContainer>
  );
};
