import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { loadFontAwesomeIcons } from "./components/utils/fontAwesome";
import { AboutPage } from "./pages/AboutPage";
import { AdminPage } from "./pages/AdminPage";
import { ConfirmEmailCodePage } from "./pages/ConfirmEmailCodePage";
import { PasswordResetPage } from "./pages/PasswordResetPage";
import { PresetPage } from "./pages/PresetPage";
import { SignInPage } from "./pages/SignInPage";
import { SignOutPage } from "./pages/SignOutPage";
import { SignUpPage } from "./pages/SignUpPage";

loadFontAwesomeIcons();

export function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/group/aave"
          element={
            <Navigate to="/preset/d283f0fd-e0d0-40da-988a-4bf9b2649a2c" />
          }
        />
        <Route
          path="/group/compound"
          element={
            <Navigate to="/preset/f57713ec-b7dc-47cc-a42d-c0f7eaff0da3" />
          }
        />
        <Route
          path="/group/euler"
          element={
            <Navigate to="/preset/6b8e0deb-a2f4-4dfd-b4b5-01bb85ac07fd" />
          }
        />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/signout" element={<SignOutPage />} />
        <Route path="/confirm-email-code" element={<ConfirmEmailCodePage />} />
        <Route path="/password-reset" element={<PasswordResetPage />} />
        <Route path="/preset/:id" element={<PresetPage />} />
        <Route
          path="/"
          element={
            <Navigate to="/preset/d283f0fd-e0d0-40da-988a-4bf9b2649a2c" />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
