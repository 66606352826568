import CreatableSelect from "react-select/creatable";
import "./index.scss";

export const RationCreatableSelect = <T extends unknown>(props: {
  options: { value: T; label: string }[];
  value: { value: T; label: string };
  onChange: (v: { value: T; label: string } | null) => void;
  large?: boolean;
}) => {
  return (
    <CreatableSelect
      className={`RationCreatableSelect ${
        props.large ? "RationCreatableSelect_large" : ""
      }`}
      isClearable
      value={props.value}
      onChange={props.onChange}
      options={props.options}
      formatCreateLabel={(v) => `use ${v}`}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "var(--highlight)",
          primary25: "var(--lightergray)",
        },
      })}
    />
  );
};
