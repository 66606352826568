import { useApolloClient } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { RationButton } from "../components/RationButton";
import { RationContainer } from "../components/RationContainer";
import { RationFlex } from "../components/RationFlex";
import { RationInput } from "../components/RationInput";
import { RationPage } from "../components/RationPage";
import { RationText } from "../components/RationText";
import { RationTitle } from "../components/RationTitle";
import { useExecution } from "../hooks/useExecution";
import { useExecutionForm } from "../hooks/useExecutionForm";
import { useLivePresets } from "../hooks/usePresets/useLivePresets";
import { usePresetForm } from "../hooks/usePresetForm";
import { RationPreset } from "../model/RationPreset";
import { CREATE_PRESET, DELETE_PRESET, UPDATE_PRESET } from "../queries";
import { AdvancedMode } from "./PresetPage/PresetExecution/Modes/AdvancedMode";
import { RationTable } from "../components/RationTable";

export function AdminPage() {
  const client = useApolloClient();
  const { presets, loading, refetch } = useLivePresets();
  const executionForm = useExecutionForm();
  const executeQuery = useExecution({ executionForm });
  const presetForm = usePresetForm({ executionForm });
  const [creating, setCreating] = useState(false);
  const [updating, setUpdating] = useState(false);

  const {
    id,
    setId,
    name,
    setName,
    description,
    setDescription,
    tags,
    setTags,
    params,
    setParams,
    group,
    setGroup,
    execution,
  } = presetForm;

  const onCreatePreset = () => {
    if (!name || !description || !group || !tags) {
      alert("Some fields are missing");
      return;
    }
    setCreating(true);
    client
      .mutate({
        mutation: CREATE_PRESET,
        variables: {
          name,
          description,
          group,
          tags: tags?.split(","),
          params: params?.split(","),
          execution,
        },
      })
      .finally(() => {
        setCreating(false);
        refetch();
      });
  };

  const onUpdatePreset = () => {
    if (!id) {
      alert("id missing");
      return;
    }
    setUpdating(true);
    client
      .mutate({
        mutation: UPDATE_PRESET,
        variables: {
          id,
          name,
          description,
          group,
          tags: tags?.split(","),
          params: params?.split(","),
          execution,
        },
      })
      .finally(() => {
        setUpdating(false);
        refetch();
      });
  };

  const onDeletePreset = (id: string) => {
    if (window.confirm("Are you sure?")) {
      client
        .mutate({
          mutation: DELETE_PRESET,
          variables: {
            id,
          },
        })
        .then(() => {
          alert("deleted successfully");
          refetch();
        })
        .catch(() => alert("failed to delete"));
    }
  };

  const {
    setUrl,
    setIterate,
    setFieldName,
    setWhereSubject,
    setWhereValue,
    setBlockinateFrom,
  } = executionForm;

  const setPreset = useCallback(
    (preset: RationPreset) => {
      const execution = JSON.parse(preset.execution);
      setUrl(execution.url);
      setIterate(execution.iterate);
      setFieldName(execution.fieldName);
      setWhereSubject(execution.whereSubject);
      setWhereValue(execution.whereValue);
      setBlockinateFrom(execution.blockinateFrom);
      setId(preset.id);
      setName(preset.name);
      setDescription(preset.description);
      setGroup(preset.group);
      setTags(preset.tags.join(","));
      setParams(preset.params.join(","));
    },
    [
      setUrl,
      setIterate,
      setFieldName,
      setWhereSubject,
      setWhereValue,
      setBlockinateFrom,
      setId,
      setName,
      setDescription,
      setGroup,
      setTags,
      setParams,
    ]
  );

  useEffect(() => {
    if (!executionForm.url && presets?.length) {
      setPreset(presets[0]);
    }
  }, [executionForm, presets, setPreset]);

  return (
    <div className="AdminPage">
      <RationPage>
        <RationTitle mb={8} mt={32}>
          Presets
        </RationTitle>
        {loading && "loading..."}
        {presets
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map((preset) => (
            <div
              key={preset.id}
              style={{ borderBottom: "solid 1px #f0f0f0", marginBottom: "4px" }}
            >
              <RationFlex justifyContent="space-between" mb={4}>
                <div>
                  <RationText>
                    {preset.name}, {preset.description}
                  </RationText>
                  <RationText variant="secondary">
                    {preset.params.join(",")}, [{preset.group}],#
                    {preset.tags.join(",#")}
                  </RationText>
                </div>
                <RationFlex columnGap={8}>
                  <RationButton
                    variant="secondary"
                    onClick={() => {
                      setPreset(preset);
                    }}
                  >
                    load
                  </RationButton>
                  <RationButton
                    variant="secondary"
                    onClick={() => {
                      onDeletePreset(preset.id);
                    }}
                  >
                    delete
                  </RationButton>
                </RationFlex>
              </RationFlex>
            </div>
          ))}
        <RationTitle mb={8} mt={32}>
          Execution form
        </RationTitle>
        <form onSubmit={(e) => e.preventDefault()}>
          <RationContainer variant="box">
            <AdvancedMode executionForm={executionForm} />
          </RationContainer>
          <RationButton
            mt={16}
            type="submit"
            onClick={executeQuery.download}
            loading={executeQuery.loading}
          >
            <FontAwesomeIcon style={{ paddingRight: "6px" }} icon="download" />
            download csv
          </RationButton>
          {executeQuery.error && (
            <RationText variant="error" pt={4}>
              {executeQuery.error.message}
            </RationText>
          )}
          <RationTitle mb={8} mt={32}>
            Preview
          </RationTitle>
          <RationContainer
            loading={executeQuery.previewQuery.loading}
            error={executeQuery.previewQuery.error}
          >
            <RationTable
              loading={executeQuery.previewQuery.loading}
              error={executeQuery.previewQuery.error}
              retryOnError={() => executeQuery.previewQuery.refetch()}
              csv={executeQuery.previewQuery.data?.execute?.thegraph?.csv}
            />
          </RationContainer>
        </form>
        <RationTitle mb={8} mt={32}>
          Create preset
        </RationTitle>
        <div>
          <RationText variant="secondary" mb={8}>
            url: {executionForm.url}
          </RationText>
          <RationText variant="secondary" mb={8}>
            query: {executionForm.iterate}, {executionForm.fieldName},{" "}
            {executionForm.whereSubject}, {executionForm.whereValue}
          </RationText>
          <RationInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="name"
            variant="long"
          />
          <RationInput
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="description"
            variant="long"
          />
          <RationInput
            value={group}
            onChange={(e) => setGroup(e.target.value)}
            placeholder="group e.g. aave"
          />
          <RationInput
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            placeholder="tags e.g. lending,borrows"
          />
          <RationInput
            value={params}
            onChange={(e) => setParams(e.target.value)}
            placeholder="params e.g. whereValue"
          />
          <RationButton loading={creating} onClick={onCreatePreset}>
            create preset
          </RationButton>
          <RationButton loading={updating} onClick={onUpdatePreset} ml={4}>
            update preset
          </RationButton>
        </div>
      </RationPage>
    </div>
  );
}
