import "./index.scss";
import { RationComponentStyle, rationStyleMapper } from "../utils/style";

export const RationTitle = (
  props: {
    children?: React.ReactNode;
    variant?: "normal" | "primary";
  } & RationComponentStyle
) => {
  const variant = props.variant || "normal";

  return (
    <h3
      className={`RationTitle RationTitle_${variant}`}
      style={rationStyleMapper(props)}
    >
      {props.children}
    </h3>
  );
};
