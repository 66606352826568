import { RationPage } from "../components/RationPage";
import { RationText } from "../components/RationText";
import { RationTitle } from "../components/RationTitle";

export function AboutPage() {
  return (
    <RationPage small>
      <div className="AboutPage">
        <RationTitle variant="primary">About</RationTitle>
        <RationText>
          Download historical interest rates for Aave, Compound and Euler in CSV
          format.
        </RationText>
        <RationText mt={16}>
          We provide standardised data with representative APYs suitable for
          like-for-like comparison of Aave and Compound. Read more about our{" "}
          <a href="https://pro.aavescan.com/articles/calculating-crypto-interest-rates">
            methodology
          </a>
          .
        </RationText>
        <RationText mt={16}>
          Aavescan Pro is built by RationAnalytics. Join us in{" "}
          <a href="https://discord.gg/xeP4j49PWM">discord</a> or contact
          rationanalytics@gmail.com.
        </RationText>
        <RationTitle variant="primary" mt={32}>
          Disclaimer
        </RationTitle>
        <RationText>
          We rely on third parties and cannot guarantee the accuracy of the data
          produced by this site. Nothing produced by Aavescan Pro is financial
          advice.
        </RationText>
      </div>
    </RationPage>
  );
}
