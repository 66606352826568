import { useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { RationContainer } from "../../components/RationContainer";
import { RationDiv } from "../../components/RationDiv";
import { RationPage } from "../../components/RationPage";
import { SectionArticles } from "../../components/SectionArticles";
import { SectionPopularCSVs } from "../../components/SectionPopularCSVs";
import { useCachedPresets } from "../../hooks/usePresets/useCachedPresets";
import { RationPreset } from "../../model/RationPreset";
import { PRESET } from "../../queries";
import { PresetExecution } from "./PresetExecution";
import { PresetHeader } from "./PresetHeader";

export function PresetPage() {
  const params = useParams();

  const presetQuery = useQuery<{
    preset: RationPreset;
  }>(PRESET, {
    variables: { id: params.id },
    fetchPolicy: "no-cache",
  });

  const preset = presetQuery?.data?.preset;

  const { presets } = useCachedPresets();

  return (
    <RationPage showSidebar>
      {preset && (
        <Helmet>
          <title>{`Download ${preset?.name}`}</title>
          <meta name="description" content={`Download ${preset?.name}`} />
          <meta
            content={`CSVs, downloads, ${preset?.tags[0]}`}
            name="keywords"
          />
          <link
            rel="canonical"
            href={`https://app.aavescan.com/preset/${preset?.id}`}
          />
        </Helmet>
      )}
      <div className="PresetPage">
        <RationContainer
          loading={presetQuery.loading}
          error={presetQuery.error}
          notFound={!presetQuery.loading && !preset}
        >
          {preset && (
            <>
              <PresetHeader preset={preset} />
              <PresetExecution preset={preset} />
            </>
          )}
        </RationContainer>
        {!presetQuery.loading && (
          <>
            <RationDiv mt={32}>
              <SectionPopularCSVs presets={presets} />
            </RationDiv>
            <RationDiv mt={32}>
              <SectionArticles />
            </RationDiv>
          </>
        )}
      </div>
    </RationPage>
  );
}
