import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import { RationContext } from "./context";
import "./index.css";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <RationContext>
      <App />
    </RationContext>
  </React.StrictMode>
);
