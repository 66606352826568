import { Link } from "react-router-dom";
import { RationFlex } from "../RationFlex";
import { RationText } from "../RationText";
import "./index.scss";

export const RationFooter = () => {
  return (
    <div className="RationFooter">
      <RationFlex
        justifyContent="space-between"
        columnGap={8}
        flexWrap="wrap"
        rowGap={8}
      >
        <div>
          <Link to="/">Aavescan Pro</Link>
          <RationText variant="secondary">
            Download live and historical crypto rates.
          </RationText>
        </div>
        <div>
          <div>
            <Link to="/about">about</Link>
          </div>
          <div>
            <a href="https://discord.gg/xeP4j49PWM">discord</a>
          </div>
          <div>
            <a href="https://pro.aavescan.com/pricing">pricing</a>
          </div>
          <div>
            <Link to="/signup">sign up</Link>
          </div>
          <div>
            <a href="https://pro.aavescan.com/terms">terms</a>
          </div>
          <div>
            <a href="https://pro.aavescan.com">home page</a>
          </div>
        </div>
      </RationFlex>
    </div>
  );
};
