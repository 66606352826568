import { RationComponentStyle, rationStyleMapper } from "../utils/style";
import "./index.scss";

export const RationInput = (
  props: RationComponentStyle &
    React.InputHTMLAttributes<HTMLInputElement> & {
      variant?: "normal" | "long";
    }
) => {
  return (
    <input
      {...props}
      value={props.value === undefined ? "" : props.value}
      className={`RationInput RationInput_${props.variant || "normal"}`}
      style={rationStyleMapper(props)}
    />
  );
};
