import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ISignUpResult,
} from "amazon-cognito-identity-js";
import {
  UserCodeConfirmation,
  UserNewPasswordConfirmation,
  UserSignIn,
  UserSignUp,
} from "./model";

const poolData = {
  UserPoolId: "us-east-2_A4qxkHPz8",
  ClientId: "2qs2q11vims25uge31nbithuoe",
};

const userPool = new CognitoUserPool(poolData);

export const signUp = (args: UserSignUp): Promise<ISignUpResult> => {
  return new Promise((resolve, reject) => {
    const dataEmail = {
      Name: "email",
      Value: args.email,
    };

    const attributeEmail = new CognitoUserAttribute(dataEmail);

    userPool.signUp(
      args.username,
      args.password,
      [attributeEmail],
      [attributeEmail],
      function (err, result) {
        if (err) {
          reject(err.message);
          return;
        }
        if (!result) {
          reject("No result returned from cognito sign up");
          return;
        }

        resolve(result);
      }
    );
  });
};

export const confirmCode = (args: UserCodeConfirmation): Promise<undefined> => {
  return new Promise((resolve, reject) => {
    const userData = {
      Username: args.username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(args.code, true, function (err, result) {
      if (err) {
        reject(err.message);
        return;
      }

      resolve(result);
    });
  });
};

export const signIn = (
  args: UserSignIn
): Promise<{ session: CognitoUserSession; cognitoUser: CognitoUser }> => {
  return new Promise((resolve, reject) => {
    const authenticationData = {
      Username: args.username,
      Password: args.password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: args.username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (session) {
        resolve({ session, cognitoUser });
      },
      onFailure: function (err) {
        reject(err?.message);
      },
    });
  });
};

export const getCurrentUser = (): Promise<{
  session?: CognitoUserSession;
  cognitoUser?: CognitoUser;
}> => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      reject("No cognito user");
    }

    /*
     * This call will automatically try and refresh the session
     * using the refresh token
     */
    cognitoUser?.getSession(function (
      err: Error,
      session: CognitoUserSession | null
    ) {
      if (err) {
        resolve({ cognitoUser });
      }

      if (!session) {
        resolve({ cognitoUser });
      }

      resolve({
        session: session as CognitoUserSession,
        cognitoUser,
      });
    });
  });
};

export const signOut = () => {
  const cognitoUser = userPool.getCurrentUser();
  cognitoUser?.signOut();
};

export const beginPasswordReset = (args: {
  username: string;
}): Promise<void> => {
  return new Promise((resolve, reject) => {
    const userData = {
      Username: args.username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: () => resolve(),
      onFailure: () => reject(),
    });
  });
};

export const confirmPasswordReset = (
  args: UserNewPasswordConfirmation
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const userData = {
      Username: args.username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(args.code, args.newPassword, {
      onSuccess: () => resolve(),
      onFailure: () => reject(),
    });
  });
};
