import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RationFlex } from "../RationFlex";
import { RationText } from "../RationText";
import { RationTitle } from "../RationTitle";
import "./index.scss";

export const ArticleLink = (props: {
  title: string;
  description: string;
  href: string;
}) => {
  return (
    <div className="ArticleLink">
      <a href={props.href}>
        <RationTitle m={0} p={0}>
          <RationFlex columnGap={4} alignItemsCenter>
            <FontAwesomeIcon icon="newspaper" />
            {props.title}
          </RationFlex>
        </RationTitle>
      </a>
      <RationText variant="secondary">{props.description}.</RationText>
    </div>
  );
};
