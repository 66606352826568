import { gql } from "@apollo/client";

export const EXECUTE_THEGRAPH = gql`
  query Execution(
    $url: String!
    $iterate: String!
    $fieldName: String!
    $whereSubject: String
    $whereValue: String
    $blockinateFrom: String
    $limit: Int
  ) {
    execute {
      thegraph(
        query: {
          url: $url
          iterate: $iterate
          fieldName: $fieldName
          whereSubject: $whereSubject
          whereValue: $whereValue
          blockinateFrom: $blockinateFrom
          limit: $limit
        }
      ) {
        csv
      }
    }
  }
`;

export const VALIDATE_THEGRAPH = gql`
  query Validation($url: String, $fieldName: String, $paramName: String) {
    validate {
      thegraph(
        query: { url: $url, fieldName: $fieldName, paramName: $paramName }
      ) {
        queriesForThisUrl
        argsForThisQuery
        fieldsForThisQuery
        inputFieldsForThisQueryWhereArg
        instancesOfParam {
          id
          name
        }
        canBlockinate
        canWhereinate
        canPaginate
      }
    }
  }
`;

export const CREATE_PRESET = gql`
  mutation CreatePreset(
    $name: String!
    $description: String!
    $execution: String!
    $group: String
    $params: [String]
    $tags: [String]
  ) {
    createPreset(
      name: $name
      description: $description
      execution: $execution
      group: $group
      params: $params
      tags: $tags
    ) {
      name
    }
  }
`;

export const UPDATE_PRESET = gql`
  mutation UpdatePreset(
    $id: String!
    $name: String
    $description: String
    $execution: String
    $group: String
    $params: [String]
    $tags: [String]
  ) {
    updatePreset(
      id: $id
      name: $name
      description: $description
      execution: $execution
      group: $group
      params: $params
      tags: $tags
    ) {
      name
    }
  }
`;

export const DELETE_PRESET = gql`
  mutation DeletePreset($id: String!) {
    deletePreset(id: $id) {
      id
    }
  }
`;

export const PRESETS = gql`
  query Presets {
    presets {
      id
      name
      description
      tags
      params
      group
      execution
    }
  }
`;

export const PRESET = gql`
  query PresetById($id: String!) {
    preset(id: $id) {
      id
      name
      description
      tags
      params
      group
      execution
    }
  }
`;

export const USER = gql`
  query User {
    user {
      username
      subscribed
    }
  }
`;

export const ANALYTICS = gql`
  query Analytics($event: String!, $username: String) {
    analytics(event: $event, username: $username) {
      event
      username
    }
  }
`;
