import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight,
  faNewspaper,
  fas,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

export const loadFontAwesomeIcons = () => {
  library.add(fas, faChevronLeft, faChevronRight, faSpinner, faNewspaper);
};
