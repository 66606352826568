import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { IS_DEV } from "../../config/env";
import { getCurrentUser } from "../auth/cognito";

export const BASE_URL = IS_DEV
  ? "http://localhost:4000/default/ration-api"
  : "https://api.aavescan.com";

const httpLink = createHttpLink({ uri: BASE_URL });

const authLink = setContext(async (_, { headers }) => {
  try {
    const user = await getCurrentUser();
    return {
      headers: {
        ...headers,
        authorization: user?.session?.getAccessToken().getJwtToken(),
      },
    };
  } catch (e) {
    console.error(e);
  }
});

export const RationClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
    query: {
      fetchPolicy: "no-cache",
    },
  },
});
