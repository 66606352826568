import { useState } from "react";
import { useExecutionForm } from "./useExecutionForm";

export const usePresetForm = (args: {
  executionForm: ReturnType<typeof useExecutionForm>;
}) => {
  const [id, setId] = useState<string>();
  const [name, setName] = useState<string>();
  const [tags, setTags] = useState<string>();
  const [group, setGroup] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [params, setParams] = useState<string>();

  return {
    id,
    setId,
    name,
    setName,
    tags,
    setTags,
    group,
    setGroup,
    description,
    setDescription,
    params,
    setParams,
    execution: JSON.stringify({
      type: "thegraph",
      url: args.executionForm.url,
      iterate: args.executionForm.iterate,
      fieldName: args.executionForm.fieldName,
      whereSubject: args.executionForm.whereSubject,
      whereValue: args.executionForm.whereValue,
      blockinateFrom: args.executionForm.blockinateFrom,
    }),
  };
};
