import { RationComponentStyle, rationStyleMapper } from "../utils/style";

export const GroupLogo = (
  props: { group: string; size: number } & RationComponentStyle
) => {
  const group = { euler: "euler-finance" }[props.group] || props.group;

  return (
    <img
      alt={props.group}
      style={{
        width: props.size + "px",
        height: props.size + "px",
        display: "block",
        ...rationStyleMapper(props),
      }}
      src={`https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/128/${group}.png`}
    />
  );
};
