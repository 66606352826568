import { RationCreatableSelect } from "../../../../../components/RationCreatableSelect";
import { RationField } from "../../../../../components/RationField";
import { RationInput } from "../../../../../components/RationInput";
import { useExecutionForm } from "../../../../../hooks/useExecutionForm";
import { formatDisplayId } from "./utils/formatDisplayId";

export const WhereValueField = (props: {
  executionForm: ReturnType<typeof useExecutionForm>;
}) => {
  const { executionForm } = props;

  const { whereSubject, whereValue, setWhereValue, instancesOfParam } =
    executionForm;

  const current = instancesOfParam?.find(({ id }) => id === whereValue);

  return (
    <>
      {whereSubject && (
        <RationField>
          <label htmlFor="preset-whereValue-input">{whereSubject}</label>
          {instancesOfParam &&
          (!instancesOfParam?.length || instancesOfParam.length === 100) ? (
            <RationInput
              value={whereValue}
              onChange={(e) => setWhereValue(e.target.value)}
              variant="long"
            />
          ) : (
            <RationCreatableSelect
              large
              value={{
                value: whereValue,
                label: current
                  ? current.name
                    ? `${current.name} (${formatDisplayId(current.id)})`
                    : formatDisplayId(current.id)
                  : whereValue || "",
              }}
              onChange={(e) => setWhereValue(e?.value as string)}
              options={
                instancesOfParam
                  ?.filter((item, index) => {
                    return (
                      instancesOfParam.findIndex((v) => v.id === item.id) ===
                      index
                    );
                  })
                  .map((item) => ({
                    value: item.id,
                    label: item.name
                      ? `${item.name} (${formatDisplayId(item.id)})`
                      : formatDisplayId(item.id),
                  }))
                  .filter((item) => !item.label.includes("AMM"))
                  .sort((a, b) => a.label.localeCompare(b.label)) || []
              }
            />
          )}
        </RationField>
      )}
    </>
  );
};
