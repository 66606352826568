const LABEL_MAP: { [key: string]: string } = {
  "0xacc030ef66f9dfeae9cbb0cd1b25654b82cfa8d5": "AMM Pool",
  "0xb53c1a33016b2dc2ff3653530bff1848a515c8c5": "V2 Pool",
};

const LABELS = Object.keys(LABEL_MAP);

export const formatDisplayId = (id?: string) => {
  let result = id?.toLowerCase() || "n/a";

  LABELS.forEach((label) => {
    result = result.replace(label, ` ${LABEL_MAP[label]}`);
  });

  if (result.length > 42) {
    result = `${result.slice(0, 15)}...${result.slice(
      result.length - 15,
      result.length
    )}`;
  }

  return result;
};
