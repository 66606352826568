import { RationPreset } from "../../model/RationPreset";
import { useCacheValue } from "../useCacheValue";

export const useCachedPresets = () => {
  const presetsQuery = useCacheValue<RationPreset[]>("presets/index");

  const presetsByGroup = presetsQuery.data?.reduce((acc, curr) => {
    acc[curr.group] = acc[curr.group]?.concat(curr) || [curr];
    return acc;
  }, {} as { [group: string]: RationPreset[] });

  return {
    loading: presetsQuery.loading,
    error: presetsQuery.error,
    presets: presetsQuery.data,
    presetsByGroup,
  };
};
