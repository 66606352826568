import { RationField } from "../../../../../components/RationField";
import { RationSelect } from "../../../../../components/RationSelect";
import { useUser } from "../../../../../context/user";
import { useExecutionForm } from "../../../../../hooks/useExecutionForm";

export const LimitField = (props: {
  executionForm: ReturnType<typeof useExecutionForm>;
}) => {
  const { executionForm } = props;
  const { user } = useUser();
  const { isAuthed, isSubscribed } = user;
  const { limit, setLimit, iterate } = executionForm;

  return iterate === "blockinate" ? (
    <></>
  ) : (
    <>
      <RationField>
        <label htmlFor="preset-limit-input">rows</label>
        <RationSelect
          value={limit}
          onChange={(newLimit) => setLimit(Number(newLimit))}
          options={
            isAuthed && isSubscribed
              ? [10, 30, 200, 1000, 5000]
              : isAuthed
              ? [10, 30]
              : [10]
          }
        />
      </RationField>
    </>
  );
};
