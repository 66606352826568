import { RationComponentStyle, rationStyleMapper } from "../utils/style";

export const RationFlex = (
  props: {
    columnGap?: number;
    rowGap?: number;
    alignItemsCenter?: boolean;
    justifyContent?: ElementCSSInlineStyle["style"]["justifyContent"];
    flexWrap?: "wrap";
    children?: React.ReactNode;
  } & RationComponentStyle &
    React.HTMLProps<HTMLDivElement>
) => {
  const style = rationStyleMapper(props);

  if (props.columnGap) {
    style.columnGap = props.columnGap + "px";
  }

  if (props.rowGap) {
    style.rowGap = props.rowGap + "px";
  }

  if (props.alignItemsCenter) {
    style.alignItems = "center";
  }

  if (props.flexWrap) {
    style.flexWrap = props.flexWrap;
  }

  if (props.justifyContent) {
    style.justifyContent = props.justifyContent;
  }

  return <div style={{ display: "flex", ...style }}>{props.children}</div>;
};
