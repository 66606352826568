import { ArticleLink } from "../ArticleLink";
import { ItemGrid } from "../ItemGrid";
import { RationTitle } from "../RationTitle";

export function SectionArticles() {
  return (
    <>
      <RationTitle variant="primary">Articles</RationTitle>
      <ItemGrid>
        <ArticleLink
          href="https://pro.aavescan.com/articles/calculating-crypto-interest-rates"
          title="Calculating lending rates"
          description="Learn how we calculate and standardise on-chain lending rates"
        />
        <ArticleLink
          href="https://pro.aavescan.com/aave"
          title="Aave"
          description="Learn more about Aave and the contract fields found in the CSV"
        />
      </ItemGrid>
    </>
  );
}
