import { useQuery } from "@apollo/client";
import { createContext, useContext } from "react";
import { RationUser } from "../../model/RationUser";
import { USER } from "../../queries";
import { useAuth } from "../auth";
import { IUserContext } from "./model";

const UserContext = createContext<IUserContext | undefined>(undefined);

function UserProvider({ children }: { children: React.ReactNode }) {
  const { isAuthed, session } = useAuth();

  const userQuery = useQuery<{ user: RationUser }>(USER, {
    skip: session === undefined,
    fetchPolicy: "no-cache",
  });

  const user = {
    isAuthed,
    isSubscribed: userQuery?.data?.user?.subscribed,
    username: userQuery?.data?.user?.username,
  };

  return (
    <UserContext.Provider
      value={{
        user,
        loading: userQuery.loading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

function useUser() {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUser must be used inside UserProvider");
  }

  return context;
}

export { UserProvider, useUser };
