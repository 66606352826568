export const formatCell = (v?: string) => {
  if (!v?.length) {
    return v;
  }

  if (v.slice(0, 2) === "0x") {
    return v.slice(0, 12);
  }

  if (v.length > 50) {
    return v.slice(0, 12);
  }

  if (/^\d+$/.test(v)) {
    if (String(v).length > 10) {
      return `${String(v).slice(0, 10)}...`;
    } else {
      return String(v).slice(0, 10);
    }
  }

  return v;
};
