import { RationButton } from "../RationButton";
import { RationDiv } from "../RationDiv";
import { RationText } from "../RationText";
import "./index.scss";

export const RationContainer = (props: {
  children?: React.ReactNode;
  loading?: boolean;
  error?: { message: string };
  retryOnError?: () => void;
  notFound?: boolean;
  variant?: "normal" | "box";
}) => {
  const {
    children,
    notFound,
    error,
    retryOnError,
    loading,
    variant = "normal",
  } = props;

  return (
    <div className={`RationContainer RationContainer_${variant}`}>
      {loading ? (
        <RationText variant="secondary">loading...</RationText>
      ) : error ? (
        error.message
      ) : notFound ? (
        "not found."
      ) : (
        children
      )}
      {error && retryOnError && (
        <RationDiv mt={8}>
          <RationButton variant="link" onClick={retryOnError}>
            retry.
          </RationButton>
        </RationDiv>
      )}
    </div>
  );
};
