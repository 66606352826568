import { ApolloError, useApolloClient, useQuery } from "@apollo/client";
import { useState } from "react";
import { RationExecution } from "../model/RationExecution";
import { ANALYTICS, EXECUTE_THEGRAPH } from "../queries";
import { useExecutionForm } from "./useExecutionForm";

export const useExecution = (props: {
  executionForm: ReturnType<typeof useExecutionForm>;
}) => {
  const client = useApolloClient();

  const { executionForm } = props;

  const [data, setData] = useState<string[][]>();
  const [dataVariables, setDataVariables] = useState<RationExecution>();
  const [error, setError] = useState<ApolloError>();
  const [loading, setLoading] = useState<boolean>();

  const variables = {
    url: executionForm.url,
    iterate: executionForm.iterate,
    fieldName: executionForm.fieldName,
    whereSubject: executionForm.whereSubject || undefined,
    whereValue: executionForm.whereValue,
    blockinateFrom: executionForm.blockinateFrom,
    limit: executionForm.limit,
  } as RationExecution;

  const fetch = async () => {
    setError(undefined);
    setLoading(true);

    try {
      const result = await client.query<{
        execute?: { thegraph?: { csv?: string[][] } };
      }>({ query: EXECUTE_THEGRAPH, variables });

      if (result.data?.execute?.thegraph?.csv) {
        setData(result.data.execute.thegraph.csv);
        setDataVariables(variables);
      }

      if (result.error) {
        setError(result.error);
      }

      setLoading(false);
      return result;
    } catch (e) {
      setLoading(false);
      setError(e as ApolloError);
      throw new Error((e as ApolloError)?.message || "Failed to fetch");
    }
  };

  const download = async () => {
    const result = await fetch();
    if (result.data?.execute?.thegraph?.csv) {
      const { csv } = result.data.execute.thegraph;

      let csvContent = "data:text/csv;charset=utf-8,";

      csv.forEach(function (rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
      });

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute(
        "download",
        [
          "ration",
          "-",
          executionForm.fieldName,
          "-",
          executionForm.whereSubject,
          "-",
          executionForm.whereValue,
          ".csv",
        ].join("")
      );
      document.body.appendChild(link);
      link.click();
    } else {
      alert(result.errors?.[0]?.message || "No data.");
    }

    try {
      await client.query({
        query: ANALYTICS,
        variables: {
          event: `Download ${executionForm.fieldName}, ${executionForm.whereValue}`,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const isInvalid =
    !executionForm.url?.length ||
    !executionForm.fieldName?.length ||
    !executionForm.iterate?.length ||
    (executionForm.whereSubject && !executionForm.whereValue?.length);

  const previewQuery = useQuery<{
    execute: { thegraph: { csv: string[][] } };
  }>(EXECUTE_THEGRAPH, {
    variables: { ...variables, limit: 5, blockinateFrom: "10d" },
    fetchPolicy: "no-cache",
    skip: Boolean(isInvalid),
  });

  return {
    data,
    dataVariables,
    download,
    error,
    fetch,
    loading,
    previewQuery,
  };
};
