import { RationPreset } from "../../model/RationPreset";
import { ItemGrid } from "../ItemGrid";
import { PresetLink } from "../PresetLink";
import { RationTitle } from "../RationTitle";

export function SectionPopularCSVs(props: {
  presets: RationPreset[] | undefined;
}) {
  return (
    <>
      <RationTitle variant="primary">Historical rates</RationTitle>
      <ItemGrid>
        {props.presets
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map((preset) => {
            return (
              <div>
                <PresetLink key={preset.id} preset={preset} />
              </div>
            );
          })}
      </ItemGrid>
    </>
  );
}
