import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RationFlex } from "../RationFlex";
import { RationText } from "../RationText";
import { RationComponentStyle, rationStyleMapper } from "../utils/style";
import "./index.scss";

export const RationButton = (
  props: {
    children?: React.ReactNode;
    loading?: boolean;
    icon?: IconProp;
    variant?: "normal" | "secondary" | "link" | "dark";
  } & RationComponentStyle &
    React.HTMLProps<HTMLButtonElement>
) => {
  const variant = props.variant || "normal";

  return (
    <button
      style={rationStyleMapper(props)}
      type={props.type as "button"}
      disabled={props.disabled || props.loading}
      onClick={props.onClick}
      className={`RationButton RationButton_${variant}`}
    >
      {props.loading ? (
        <RationFlex alignItemsCenter columnGap={8}>
          <FontAwesomeIcon spin icon="spinner" />
          <RationText>loading</RationText>
        </RationFlex>
      ) : props.children !== undefined ? (
        props.children
      ) : props.icon !== undefined ? (
        <FontAwesomeIcon icon={props.icon as IconProp} />
      ) : undefined}
    </button>
  );
};
