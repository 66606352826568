import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { createContext, useContext, useEffect, useState } from "react";
import * as cognito from "./cognito";
import {
  IAuthContext,
  UserCodeConfirmation,
  UserNewPasswordConfirmation,
  UserSignIn,
  UserSignUp,
} from "./model";

const AuthContext = createContext<IAuthContext | undefined>(undefined);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>();
  const [session, setSession] = useState<CognitoUserSession>();

  const [requiresRefresh, setRequiresRefresh] = useState<boolean>(true);

  const signUp = async (args: UserSignUp) => {
    await cognito.signUp(args);
  };

  const confirmCode = async (args: UserCodeConfirmation) => {
    await cognito.confirmCode(args);
  };

  const beginPasswordReset = async (args: { username: string }) => {
    await cognito.beginPasswordReset(args);
  };

  const confirmPasswordReset = async (args: UserNewPasswordConfirmation) => {
    await cognito.confirmPasswordReset(args);
  };

  const signIn = async (args: UserSignIn) => {
    await cognito.signIn(args);
    setRequiresRefresh(true);
  };

  const signOut = () => {
    cognito.signOut();
    setRequiresRefresh(true);
  };

  useEffect(() => {
    if (requiresRefresh) {
      cognito
        .getCurrentUser()
        .then(({ session, cognitoUser }) => {
          setSession(session);
          setCognitoUser(cognitoUser);
        })
        .catch(() => {
          setSession(undefined);
          setCognitoUser(undefined);
        })
        .finally(() => {
          setRequiresRefresh(false);
        });
    }
  }, [requiresRefresh]);

  return (
    <AuthContext.Provider
      value={{
        cognitoUser,
        session,
        signUp,
        confirmCode,
        signIn,
        signOut,
        beginPasswordReset,
        confirmPasswordReset,
        isAuthed: cognitoUser !== undefined && session !== undefined,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used inside AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
