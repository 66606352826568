import { useApolloClient, useQuery } from "@apollo/client";
import { useState } from "react";
import { VALIDATE_THEGRAPH } from "../../queries";

export const useExecutionForm = (init?: {
  url?: string;
  iterate?: string;
  fieldName?: string;
  whereSubject?: string;
  whereValue?: string;
  blockinateFrom?: string;
  limit?: number;
}) => {
  const [url, setUrl] = useState(init?.url);
  const [iterate, setIterate] = useState(init?.iterate);
  const [fieldName, setFieldName] = useState(init?.fieldName);
  const [whereSubject, setWhereSubject] = useState(init?.whereSubject);
  const [whereValue, setWhereValue] = useState(init?.whereValue);
  const [blockinateFrom, setBlockinateFrom] = useState(init?.blockinateFrom);
  const [limit, setLimit] = useState<number>(init?.limit || 10);

  const client = useApolloClient();

  const validateQuery = useQuery<{
    validate: {
      thegraph: {
        queriesForThisUrl: string[];
        argsForThisQuery: string[];
        fieldsForThisQuery: string[];
        inputFieldsForThisQueryWhereArg: string[];
        instancesOfParam: { name: string; id: string }[];
        canBlockinate: boolean;
        canWhereinate: boolean;
        canPaginate: boolean;
      };
    };
  }>(VALIDATE_THEGRAPH, {
    variables: {
      url,
      fieldName,
      paramName: whereSubject === "id" ? fieldName : whereSubject,
    },
    client,
    fetchPolicy: "no-cache",
    skip: !url,
  });

  const loading = validateQuery.loading;
  const error = validateQuery.error;
  const data = validateQuery.data?.validate?.thegraph;
  const instancesOfParam = data?.instancesOfParam;
  const canBlockinate = data?.canBlockinate;
  const canWhereinate = data?.canWhereinate;
  const canPaginate = data?.canPaginate;
  const queriesForThisUrl = data?.queriesForThisUrl;
  const argsForThisQuery = data?.argsForThisQuery;
  const inputFieldsForThisQueryWhereArg = data?.inputFieldsForThisQueryWhereArg;

  return {
    url,
    setUrl,
    iterate,
    setIterate,
    fieldName,
    setFieldName,
    whereSubject,
    setWhereSubject,
    whereValue,
    setWhereValue,
    blockinateFrom,
    setBlockinateFrom,
    limit,
    setLimit,
    loading,
    error,
    queriesForThisUrl,
    argsForThisQuery,
    inputFieldsForThisQueryWhereArg,
    instancesOfParam,
    canBlockinate,
    canWhereinate,
    canPaginate,
  };
};
