import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RationButton } from "../components/RationButton";
import { RationDiv } from "../components/RationDiv";
import { RationFlex } from "../components/RationFlex";
import { RationInput } from "../components/RationInput";
import { RationPage } from "../components/RationPage";
import { RationText } from "../components/RationText";
import { RationTitle } from "../components/RationTitle";
import { useAuth } from "../context/auth";

export function SignInPage() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (auth?.isAuthed) {
      navigate("/signout");
    }
  }, [auth?.isAuthed, navigate]);

  const onLogin = async () => {
    if (auth && username && password) {
      try {
        setLoading(true);
        await auth.signIn({ username, password });
        navigate("/");
      } catch (e: any) {
        setError(String(e?.message || e || "failed to sign in"));
      }
      setLoading(false);
    }
  };

  return (
    <RationPage small>
      <div>
        <RationTitle variant="primary">sign in</RationTitle>
        <form onSubmit={(e) => e.preventDefault()}>
          <RationDiv>
            <RationInput
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="username"
              required
              minLength={2}
            />
          </RationDiv>
          <RationDiv mt={8} mb={8}>
            <RationInput
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="password"
              required
              minLength={8}
            />
          </RationDiv>
          <RationButton
            type="submit"
            onClick={onLogin}
            mt={8}
            loading={loading}
          >
            sign in
          </RationButton>
        </form>
        <RationText m={8} variant="error">
          {error}
        </RationText>
        <RationFlex
          alignItemsCenter
          mt={32}
          columnGap={8}
          rowGap={8}
          flexWrap="wrap"
        >
          <RationText variant="secondary">don't have an account?</RationText>
          <Link to="/signup">create account</Link>
        </RationFlex>
        <RationFlex
          alignItemsCenter
          mt={8}
          columnGap={8}
          rowGap={8}
          flexWrap="wrap"
        >
          <RationText variant="secondary">forgotten password?</RationText>
          <Link to="/password-reset">reset password</Link>
        </RationFlex>
      </div>
    </RationPage>
  );
}
