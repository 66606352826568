import Select, { Theme } from "react-select";
import "./index.scss";

export const RationSelect = <T extends unknown>(props: {
  value: T;
  options: T[];
  onChange: (v: T) => void;
  formatItem?: (v: T) => string;
}) => {
  const formatItem = props.formatItem || String;

  return (
    <Select
      className="RationSelect"
      isSearchable={false}
      value={{ value: props.value, label: formatItem(props.value) }}
      onChange={(v) => {
        if (v) {
          props.onChange(v?.value);
        }
      }}
      options={props.options.map((option) => ({
        value: option,
        label: formatItem(option),
      }))}
      theme={(theme: Theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "var(--highlight)",
          primary25: "var(--lightergray)",
        },
      })}
    />
  );
};
