import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RationButton } from "../components/RationButton";
import { RationFlex } from "../components/RationFlex";
import { RationInput } from "../components/RationInput";
import { RationPage } from "../components/RationPage";
import { useAuth } from "../context/auth";

export function ConfirmEmailCodePage() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>();
  const [code, setCode] = useState<string>();
  const [error, setError] = useState<string>();

  const onConfirm = async () => {
    if (auth && username && code) {
      try {
        await auth.confirmCode({ username, code });
        navigate("/signin");
      } catch (e: any) {
        setError(String(e?.message || e || "failed to confirm code"));
      }
    }
  };

  return (
    <RationPage small>
      <div className="ConfirmEmailCodePage">
        {auth?.isAuthed ? (
          <>
            <p>you're signed in as {auth.cognitoUser?.getUsername()}</p>
          </>
        ) : (
          <>
            <h3>confirm email</h3>
            <p>enter the code sent to your email.</p>
            <form onSubmit={(e) => e.preventDefault()}>
              <RationFlex columnGap={8} mb={8}>
                <RationInput
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="username"
                  required
                  minLength={2}
                />
                <RationInput
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="code"
                  required
                  minLength={4}
                />
              </RationFlex>
              <RationButton type="submit" onClick={onConfirm}>
                confirm
              </RationButton>
            </form>
            {error}
          </>
        )}
      </div>
    </RationPage>
  );
}
