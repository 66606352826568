import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RationButton } from "../components/RationButton";
import { RationDiv } from "../components/RationDiv";
import { RationFlex } from "../components/RationFlex";
import { RationInput } from "../components/RationInput";
import { RationPage } from "../components/RationPage";
import { RationText } from "../components/RationText";
import { RationTitle } from "../components/RationTitle";
import { useAuth } from "../context/auth";

export function SignUpPage() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (auth?.isAuthed) {
      navigate("/signout");
    }
  }, [auth?.isAuthed, navigate]);

  const onCreateAccount = async () => {
    if (auth && username && password && email) {
      if (password === passwordConfirmation) {
        try {
          setLoading(true);
          await auth.signUp({ username, password, email });
          navigate("/confirm-email-code");
        } catch (e: any) {
          setError(String(e?.message || e || "failed to create account"));
        }
        setLoading(false);
      }
    }
  };

  return (
    <RationPage small>
      <div>
        <RationTitle variant="primary">create account</RationTitle>
        <form onSubmit={(e) => e.preventDefault()}>
          <RationDiv mb={8}>
            <RationInput
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="username"
              required
              minLength={2}
            />
          </RationDiv>
          <RationDiv mb={8}>
            <RationInput
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="email"
              required
              minLength={5}
            />
          </RationDiv>
          <RationDiv mb={8}>
            <RationInput
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="password"
              required
              minLength={8}
            />
          </RationDiv>
          <RationDiv mb={8}>
            <RationInput
              type="password"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              placeholder="confirm password"
              required
              minLength={8}
              pattern={password}
            />
          </RationDiv>
          <RationButton
            type="submit"
            onClick={onCreateAccount}
            mt={8}
            loading={loading}
          >
            sign up
          </RationButton>
        </form>
        <RationText m={8} variant="error">
          {error}
        </RationText>
        <RationFlex
          alignItemsCenter
          mt={32}
          columnGap={8}
          rowGap={8}
          flexWrap="wrap"
        >
          <RationText variant="secondary">already have an account?</RationText>
          <Link to="/signin">sign in</Link>
        </RationFlex>
      </div>
    </RationPage>
  );
}
