import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RationButton } from "../components/RationButton";
import { RationPage } from "../components/RationPage";
import { RationText } from "../components/RationText";
import { RationTitle } from "../components/RationTitle";
import { SUBSCRIBE_LINK } from "../config/misc";
import { useAuth } from "../context/auth";
import { useUser } from "../context/user";

export function SignOutPage() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { user } = useUser();

  const onSignOut = () => {
    auth.signOut();
  };

  useEffect(() => {
    if (!auth?.isAuthed) {
      navigate("/signin");
    }
  }, [navigate, auth?.isAuthed]);

  return (
    <RationPage small>
      <div>
        <RationTitle variant="primary">Account</RationTitle>
        <RationText>You're signed in as {user?.username || "..."}.</RationText>
        <RationTitle>Subscription</RationTitle>
        {user.isSubscribed ? (
          <RationText mt={16}>
            You're subscribed. You can now download up to 3 years of historical
            data. View and manage your subscription with{" "}
            <a href="https://billing.stripe.com/p/login/eVa29kgyx1gF2By7ss">
              Stripe
            </a>
            .
          </RationText>
        ) : (
          <>
            <RationText mt={16}>
              You're not subscribed yet.{" "}
              <a href={SUBSCRIBE_LINK} rel="noreferrer" target="_blank">
                Subscribe
              </a>{" "}
              to fetch up to 3 years of historical data.
            </RationText>
            <RationText mt={16}>
              If you have recently subscribed please allow up to 12 hours for
              your account to be upgraded.
            </RationText>
          </>
        )}
        <RationTitle>Contact</RationTitle>
        <RationText mt={16}>
          Join our <a href="https://discord.gg/QUBxf9EH">discord</a> or send an
          email to rationanalytics@gmail.com for any requests or assistance.
        </RationText>
        <RationButton onClick={onSignOut} mt={32}>
          sign out
        </RationButton>
      </div>
    </RationPage>
  );
}
