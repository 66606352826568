export interface RationComponentStyle {
  p?: number;
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
  m?: number;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  width?: string;
  minHeight?: string;
}

export const rationStyleMapper = (arg: RationComponentStyle) => {
  const style = {} as React.CSSProperties;

  if (arg.m !== undefined) {
    style.margin = arg.m + "px";
  }
  if (arg.mt !== undefined) {
    style.marginTop = arg.mt + "px";
  }
  if (arg.mb !== undefined) {
    style.marginBottom = arg.mb + "px";
  }
  if (arg.ml !== undefined) {
    style.marginLeft = arg.ml + "px";
  }
  if (arg.mr !== undefined) {
    style.marginRight = arg.mr + "px";
  }
  if (arg.p !== undefined) {
    style.padding = arg.p + "px";
  }
  if (arg.pt !== undefined) {
    style.paddingTop = arg.pt + "px";
  }
  if (arg.pb !== undefined) {
    style.paddingBottom = arg.pb + "px";
  }
  if (arg.pl !== undefined) {
    style.paddingLeft = arg.pl + "px";
  }
  if (arg.pr !== undefined) {
    style.paddingRight = arg.pr + "px";
  }
  if (arg.width !== undefined) {
    style.width = arg.width;
  }
  if (arg.minHeight !== undefined) {
    style.minHeight = arg.minHeight;
  }

  return style;
};
