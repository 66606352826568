import { RationComponentStyle, rationStyleMapper } from "../utils/style";
import "./index.scss";

export const RationText = (
  props: {
    children?: React.ReactNode;
    variant?: "normal" | "secondary" | "error";
  } & RationComponentStyle
) => {
  const variant = props.variant || "normal";

  return (
    <div
      className={`RationText RationText_${variant}`}
      style={rationStyleMapper(props)}
    >
      {props.children}
    </div>
  );
};
