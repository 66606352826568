import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useUser } from "../../context/user";
import "./index.scss";

export const RationHeader = (props: { variant?: "normal" | "light" }) => {
  const { user } = useUser();
  const { isAuthed, username } = user;

  return (
    <div className={`RationHeader RationHeader_${props.variant || "normal"}`}>
      <div className="RationHeader_ration">
        <FontAwesomeIcon icon="magnifying-glass" style={{ paddingRight: "4px" }} />
        <Link to="/">Aavescan Pro</Link>
      </div>
      <div>
        {isAuthed ? (
          <Link to="/signout">{username}</Link>
        ) : (
          <Link to="/signin">sign in</Link>
        )}
      </div>
    </div>
  );
};
