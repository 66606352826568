import { GroupLogo } from "../../../components/GroupLogo";
import { RationFlex } from "../../../components/RationFlex";
import { RationText } from "../../../components/RationText";
import { RationTitle } from "../../../components/RationTitle";
import { RationPreset } from "../../../model/RationPreset";

export const PresetHeader = (props: { preset: RationPreset }) => {
  const { preset } = props;

  return (
    <>
      <RationFlex
        alignItemsCenter
        columnGap={8}
        mb={8}
        justifyContent="space-between"
      >
        <RationTitle variant="primary" m={0}>
          <RationFlex alignItemsCenter columnGap={4}>
            <GroupLogo group={preset.group} size={20} />
            <div>{preset.name}</div>
          </RationFlex>
        </RationTitle>
        {window.innerWidth > 700 && (
          <RationText variant="secondary">[{preset.group}]</RationText>
        )}
      </RationFlex>
      <RationText variant="secondary">{preset.description}.</RationText>
    </>
  );
};
