import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RationButton } from "../components/RationButton";
import { RationDiv } from "../components/RationDiv";
import { RationInput } from "../components/RationInput";
import { RationPage } from "../components/RationPage";
import { RationText } from "../components/RationText";
import { RationTitle } from "../components/RationTitle";
import { useAuth } from "../context/auth";

export function PasswordResetPage() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>();
  const [code, setCode] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [error, setError] = useState<string>();

  const [hasBegun, setHasBegun] = useState(false);

  const onConfirm = async () => {
    if (auth && username && code && newPassword) {
      try {
        await auth.confirmPasswordReset({ username, code, newPassword });
        navigate("/signin");
      } catch (e: any) {
        setError(String(e?.message || e || "failed to confirm code"));
      }
    }
  };

  const onBegin = async () => {
    if (auth && username) {
      try {
        await auth.beginPasswordReset({ username });
        setHasBegun(true);
      } catch (e: any) {
        setError(String(e?.message || e || "failed to begin password reset"));
      }
    }
  };

  return (
    <RationPage small>
      <div className="PasswordResetPage">
        {!hasBegun ? (
          <>
            <RationTitle variant="primary">reset password</RationTitle>
            <RationText mb={16}>
              enter your username to reset your password.
            </RationText>
            <form onSubmit={(e) => e.preventDefault()}>
              <RationDiv mb={8}>
                <RationInput
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="username"
                  required
                  minLength={2}
                />
              </RationDiv>
              <RationButton type="submit" onClick={onBegin} mt={8}>
                confirm
              </RationButton>
            </form>
          </>
        ) : (
          <>
            <RationTitle variant="primary">new password</RationTitle>
            <RationText mb={16}>
              now enter the password reset confirmation code sent to your email
              and choose a new password.
            </RationText>
            <form onSubmit={(e) => e.preventDefault()}>
              <RationDiv>
                <RationInput
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="username"
                  required
                  minLength={2}
                />
              </RationDiv>
              <RationDiv mt={8}>
                <RationInput
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="code"
                  required
                  minLength={4}
                />
              </RationDiv>
              <RationDiv mt={8} mb={8}>
                <RationInput
                  type="text"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="new password"
                  required
                  minLength={8}
                />
              </RationDiv>
              <RationButton type="submit" onClick={onConfirm} mt={8}>
                confirm password
              </RationButton>
            </form>
            {error}
          </>
        )}
      </div>
    </RationPage>
  );
}
