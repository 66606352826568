import { RationField } from "../../../../../components/RationField";
import { RationSelect } from "../../../../../components/RationSelect";
import { useUser } from "../../../../../context/user";
import { useExecutionForm } from "../../../../../hooks/useExecutionForm";

export const BlockinateField = (props: {
  executionForm: ReturnType<typeof useExecutionForm>;
}) => {
  const { executionForm } = props;

  const { iterate, blockinateFrom, setBlockinateFrom } = executionForm;

  const { user } = useUser();
  const { isAuthed, isSubscribed } = user;

  return (
    <>
      {iterate === "blockinate" && (
        <>
          <RationField>
            <label htmlFor="preset-blockinateFrom-input">period</label>
            <RationSelect
              value={blockinateFrom}
              onChange={setBlockinateFrom}
              options={
                isAuthed && isSubscribed
                  ? ["1h", "24h", "5d", "10d", "1M", "6M", "12M", "24M", "36M", "48M"]
                  : isAuthed
                  ? ["1h", "24h", "5d", "10d", "1M"]
                  : ["1h", "24h", "5d", "10d"]
              }
            />
          </RationField>
        </>
      )}
    </>
  );
};
