import { Link } from "react-router-dom";
import { RationText } from "../RationText";
import { RationTitle } from "../RationTitle";
import { RationPreset } from "../../model/RationPreset";
import "./index.scss";
import { GroupLogo } from "../GroupLogo";
import { RationFlex } from "../RationFlex";

export const PresetLink = (props: { preset: RationPreset }) => {
  const { preset } = props;

  return (
    <div className="PresetLink">
      <Link to={`/preset/${preset.id}`}>
        <RationTitle m={0} p={0}>
          <RationFlex columnGap={4}>
            <GroupLogo group={preset.group} size={18} />
            {preset.name}
          </RationFlex>
        </RationTitle>
      </Link>
      <RationText variant="secondary">{preset.description}.</RationText>
    </div>
  );
};
