import { useQuery } from "@apollo/client";
import { RationPreset } from "../../model/RationPreset";
import { PRESETS } from "../../queries";

export const useLivePresets = () => {
  const presetsQuery = useQuery<{
    presets: RationPreset[];
  }>(PRESETS, { fetchPolicy: "no-cache" });

  const presetsByGroup = presetsQuery.data?.presets.reduce((acc, curr) => {
    acc[curr.group] = acc[curr.group]?.concat(curr) || [curr];
    return acc;
  }, {} as { [group: string]: RationPreset[] });

  return {
    loading: presetsQuery.loading,
    error: presetsQuery.error,
    presets: presetsQuery.data?.presets,
    presetsByGroup,
    refetch: presetsQuery.refetch,
  };
};
