import { ApolloProvider } from "@apollo/client";
import React from "react";
import { RationClient } from "./apollo";
import { AuthProvider } from "./auth";
import { UserProvider } from "./user";

export const RationContext = (props: { children: React.ReactNode }) => {
  return (
    <ApolloProvider client={RationClient}>
      <AuthProvider>
        <UserProvider>{props.children}</UserProvider>
      </AuthProvider>
    </ApolloProvider>
  );
};
