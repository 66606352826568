import { Link, useLocation } from "react-router-dom";
import { useCachedPresets } from "../../../../hooks/usePresets/useCachedPresets";
import { RationContainer } from "../../../RationContainer";
import "./index.scss";

export const PresetSidebar = () => {
  const presetsQuery = useCachedPresets();

  const { pathname } = useLocation();

  return (
    <div className="PresetSidebar">
      <RationContainer
        loading={presetsQuery.loading}
        error={presetsQuery.error}
      >
        {presetsQuery.presets
          ?.slice()
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((preset) => (
            <Link
              key={preset.id}
              to={`/preset/${preset.id}`}
              className={
                pathname?.includes(preset.id) ? "Link_selected" : undefined
              }
            >
              {preset.name}
            </Link>
          ))}
      </RationContainer>
    </div>
  );
};
