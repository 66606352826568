import { Link } from "react-router-dom";
import { RationText } from "../../../../../components/RationText";
import { SUBSCRIBE_LINK } from "../../../../../config/misc";
import { useUser } from "../../../../../context/user";
import { useExecutionForm } from "../../../../../hooks/useExecutionForm";
import { BlockinateField } from "../../Fields/BlockinateField";
import { LimitField } from "../../Fields/LimitField";
import { WhereValueField } from "../../Fields/WhereValueField";

export const NormalMode = (props: {
  executionForm: ReturnType<typeof useExecutionForm>;
}) => {
  const { executionForm } = props;
  const { user } = useUser();
  const { isAuthed, isSubscribed } = user;

  return (
    <>
      <WhereValueField executionForm={executionForm} />
      <BlockinateField executionForm={executionForm} />
      <LimitField executionForm={executionForm} />
      {!isAuthed && (
        <RationText variant="secondary" p={0} pt={8}>
          <Link to="/signup">sign up</Link> to fetch 30 days or{" "}
          <a href={SUBSCRIBE_LINK} rel="noreferrer" target="_blank">
            subscribe
          </a>{" "}
          to fetch 3 years of historical data
        </RationText>
      )}
      {isAuthed && !isSubscribed && (
        <RationText variant="secondary" p={8}>
          <a href={SUBSCRIBE_LINK} rel="noreferrer" target="_blank">
            subscribe
          </a>{" "}
          to fetch 3 years of historical data
        </RationText>
      )}
    </>
  );
};
