import { useEffect } from "react";
import { RationFlex } from "../RationFlex";
import { RationFooter } from "../RationFooter";
import { RationHeader } from "../RationHeader";
import "./index.scss";
import { PresetSidebar } from "./sub-components/PresetSidebar";

export const RationPage = (props: {
  children?: React.ReactNode;
  showSidebar?: boolean;
  small?: boolean;
}) => {
  useEffect(() => {
    if (props.showSidebar || props.small) {
      window.scrollTo({ top: 0 });
    }
  }, [props.showSidebar, props.small]);

  return (
    <div className="RationPage">
      <RationHeader
        variant={props.showSidebar || props.small ? "light" : "normal"}
      />
      <div className="RationPage_content">
        {window.innerWidth > 800 && props.showSidebar ? (
          <RationFlex columnGap={8}>
            <PresetSidebar />
            <div className="RationPage_childrenWithSidebar">
              {props.children}
            </div>
          </RationFlex>
        ) : props.small ? (
          <div className="RationPage_childrenWithoutSidebar_small">
            {props.children}
          </div>
        ) : (
          <div className="RationPage_childrenWithoutSidebar">
            {props.children}
          </div>
        )}
      </div>
      <RationFooter />
    </div>
  );
};
