import "./index.scss";

export const ItemGrid = (props: {
  children?: React.ReactNode;
  variant?: "normal" | "thin";
}) => (
  <div className={`ItemGrid ItemGrid_${props.variant || "normal"}`}>
    {props.children}
  </div>
);
