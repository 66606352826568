import { RationComponentStyle, rationStyleMapper } from "../utils/style";

export const RationDiv = (
  props: {
    children?: React.ReactNode;
  } & RationComponentStyle &
    React.HTMLProps<HTMLButtonElement>
) => {
  return <div style={rationStyleMapper(props)}>{props.children}</div>;
};
