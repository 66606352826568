import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { RationButton } from "../../../components/RationButton";
import { RationContainer } from "../../../components/RationContainer";
import { RationDiv } from "../../../components/RationDiv";
import { RationFlex } from "../../../components/RationFlex";
import { RationTable } from "../../../components/RationTable";
import { RationText } from "../../../components/RationText";
import { RationTitle } from "../../../components/RationTitle";
import { BASE_URL } from "../../../context/apollo";
import { useExecution } from "../../../hooks/useExecution";
import { useExecutionForm } from "../../../hooks/useExecutionForm";
import { RationExecution } from "../../../model/RationExecution";
import { RationPreset } from "../../../model/RationPreset";
import { AdvancedMode } from "./Modes/AdvancedMode";
import { NormalMode } from "./Modes/NormalMode";

export const PresetExecution = (props: {
  preset: RationPreset;
  defaultMode?: "normal" | "custom query";
}) => {
  const { defaultMode = "normal" } = props;
  const [mode] = useState<"normal" | "custom query">(defaultMode);
  const execution = JSON.parse(props.preset.execution) as RationExecution;
  const executionForm = useExecutionForm(execution);
  const executeQuery = useExecution({ executionForm });

  const protocolId = executionForm.url?.split("/").at(-1);
  const marketId = executionForm.whereValue;
  const from = executionForm.blockinateFrom;

  const apiUrl = [
    `${BASE_URL}?`,
    "apiKey=YourApiKey",
    `protocolId=${protocolId}`,
    `marketId=${marketId}`,
    `from=${from}`,
  ].join("&");

  return (
    <RationDiv className="PresetExecution" mt={16}>
      <form onSubmit={(e) => e.preventDefault()}>
        <RationContainer variant="box">
          {mode === "normal" && <NormalMode executionForm={executionForm} />}
          {mode === "custom query" && (
            <AdvancedMode executionForm={executionForm} />
          )}
        </RationContainer>
        <RationFlex
          mt={16}
          alignItemsCenter
          flexWrap="wrap"
          rowGap={4}
          columnGap={4}
        >
          <RationButton
            type="submit"
            onClick={executeQuery.download}
            loading={executeQuery.loading}
            ml={0}
          >
            <FontAwesomeIcon style={{ paddingRight: "6px" }} icon="download" />
            download csv
          </RationButton>
        </RationFlex>
      </form>
      {executeQuery.error && (
        <RationText variant="secondary" pt={12}>
          {executionForm.iterate === "blockinate"
            ? executeQuery.error.message?.replace(
                "Load failed",
                "We're now indexing this item for you. Please try again in 2 minutes or contact rationanalytics@gmail.com for help."
              )
            : executeQuery.error.message}{" "}
        </RationText>
      )}
      <RationTitle mb={2} mt={32} variant="primary">
        Preview
      </RationTitle>
      <RationText mb={12} variant="secondary">
        View a sample of the data
      </RationText>
      <RationDiv minHeight="300px">
        <RationContainer
          loading={executeQuery.previewQuery.loading}
          error={executeQuery.previewQuery.error}
          retryOnError={() => executeQuery.previewQuery.refetch()}
        >
          <RationTable
            loading={executeQuery.previewQuery.loading}
            error={executeQuery.previewQuery.error}
            retryOnError={() => executeQuery.previewQuery.refetch()}
            csv={executeQuery.previewQuery.data?.execute?.thegraph?.csv}
          />
        </RationContainer>
      </RationDiv>
      <RationTitle mb={2} mt={32} variant="primary">
        REST API
      </RationTitle>
      <RationText mb={24} variant="secondary">
        Query this data via HTTP GET request.
      </RationText>
      <RationFlex columnGap={4} mb={12}>
        <RationText variant="secondary">protocolId:</RationText>
        <RationText>
          <code>{protocolId}</code>
        </RationText>
      </RationFlex>
      <RationFlex columnGap={4} mb={12}>
        <RationText variant="secondary">marketId:</RationText>
        <RationText>
          <code>{marketId}</code>
        </RationText>
      </RationFlex>
      <RationFlex columnGap={4} mb={12}>
        <RationText variant="secondary">from:</RationText>
        <RationText>
          <code>{from}</code>
        </RationText>
      </RationFlex>
      <RationFlex columnGap={4} mb={12}>
        <RationText variant="secondary">URL:</RationText>
        <div
          onCopy={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText(apiUrl);
          }}
        >
          <RationText>
            <a href={apiUrl} target="_blank" rel="noreferrer">
              {BASE_URL}
              <br />
              <span style={{ display: "block", marginLeft: "20px" }}>
                ?apiKey=YourApiKey
              </span>
              <span style={{ display: "block", marginLeft: "20px" }}>
                &protocolId={protocolId}
              </span>
              <span style={{ display: "block", marginLeft: "20px" }}>
                &marketId={marketId}
              </span>
              <span style={{ display: "block", marginLeft: "20px" }}>
                &from={from}
              </span>
            </a>
          </RationText>
        </div>
      </RationFlex>
    </RationDiv>
  );
};
