import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RationButton } from "../../../../../components/RationButton";
import { RationField } from "../../../../../components/RationField";
import { RationInput } from "../../../../../components/RationInput";
import { RationSelect } from "../../../../../components/RationSelect";
import { RationText } from "../../../../../components/RationText";
import { useExecutionForm } from "../../../../../hooks/useExecutionForm";
import { BlockinateField } from "../../Fields/BlockinateField";
import { LimitField } from "../../Fields/LimitField";
import { WhereValueField } from "../../Fields/WhereValueField";

export const AdvancedMode = (props: {
  executionForm: ReturnType<typeof useExecutionForm>;
}) => {
  const { executionForm } = props;

  const {
    url,
    setUrl,
    iterate,
    setIterate,
    fieldName,
    setFieldName,
    whereSubject,
    setWhereSubject,
    queriesForThisUrl,
    argsForThisQuery,
    inputFieldsForThisQueryWhereArg,
    canPaginate,
    canBlockinate,
    canWhereinate,
    loading,
    error,
  } = executionForm;

  const fieldNames = queriesForThisUrl || [];

  const whereSubjects =
    inputFieldsForThisQueryWhereArg?.concat(
      argsForThisQuery?.includes("id") ? ["id"] : []
    ) || [];

  return (
    <>
      <RationField>
        <label htmlFor="preset-url-input">url</label>
        <RationInput
          required
          id="preset-url-input"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="url"
          variant="long"
        />
      </RationField>
      <RationField>
        <label id="preset-iterate-input">iterate</label>
        <RationSelect
          options={
            [
              canPaginate && "paginate",
              canWhereinate && "wherinate",
              canBlockinate && "blockinate",
            ].filter(Boolean) as string[]
          }
          value={iterate}
          onChange={(v) => setIterate(v)}
        />
      </RationField>
      <RationField>
        <label htmlFor="preset-fieldName-input">field</label>
        <RationSelect
          value={fieldName}
          onChange={setFieldName}
          options={fieldNames}
        />
        {loading && <FontAwesomeIcon icon="spinner" spin />}
      </RationField>
      {(whereSubjects?.length || 0) > 0 && (
        <RationField>
          <label htmlFor="preset-whereSubject-input">where</label>
          {whereSubject && (
            <RationSelect
              value={whereSubject}
              onChange={setWhereSubject}
              options={whereSubjects}
            />
          )}
          <RationButton
            onClick={() =>
              setWhereSubject(whereSubject ? undefined : whereSubjects[0])
            }
          >
            {whereSubject ? "remove" : "add"}
          </RationButton>
        </RationField>
      )}
      <WhereValueField executionForm={executionForm} />
      <BlockinateField executionForm={executionForm} />
      <LimitField executionForm={executionForm} />
      {error && <RationText variant="error">{error?.message}</RationText>}
    </>
  );
};
