import { useEffect, useState } from "react";
import { CACHE_BASE_URL } from "../config/misc";

export const useCacheValue = <T extends unknown>(key: string) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<{ message: string }>();
  const [data, setData] = useState<T>();

  useEffect(() => {
    setLoading(true);
    fetch(`${CACHE_BASE_URL}/${key}.json`)
      .then(async (response) => {
        try {
          if (response.status !== 200) {
            return setError(
              response.status === 403
                ? { message: `${key} not found` }
                : { message: "something went wrong" }
            );
          }
          const json = await response.json();
          setData(json);
        } catch (e) {
          setError((e as Error) || { message: "failed to parse JSON" });
        }
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [key]);

  return {
    loading,
    error,
    data,
  };
};
